.App1 {
  
  

    font-family: Montserrat;
    color: black;
    
    align-items: center;
    font-size: calc(12px + 1vmin);
   
    
    
  


  }



  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  
.text1 {

  color: #5643CC;
  font-size: calc(25px + 4vmin);
font-weight: bolder;
font-family: poppinsbold;
}

  


  
  body {
  
    background-color:#e8e1eb;

    background-position: top;
    background-repeat: repeat ;
   background-size: cover;     

   
   
    font-family: Arial;
    align-items: center;
    



  
  
  


  
  }
  







  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;


    




  }
  
  
  
  
  
  

  .container {

    padding:20px;





  }






  .card
   
  {
   
   

    background-color:rgb(25, 25, 51);


    



   
  border-color: transparent;
  
  

  border-radius: 15px;
  
  
  color: rgb(218, 53, 176);
  
   
  


  }
  
  
  .card-body {
  
    background-color: transparent;
    
    
  
  }
  
  
  .card-body1 {

    background-color: transparent;

    


  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;






  }
  
  
  
  
  
  
  
  
  
  


  .card-title {

  
  
    color: rgb(218, 53, 176);

  }
  



  .pointer         { cursor: pointer; }
    
  
  
  
  
  
  
  
  
  




.button-36 {
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: Montserrat;
  
  font-size: 16px;
  font-weight: 700;
  height: 4rem;
  padding: 0 1.6rem;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}

@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}


